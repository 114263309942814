/*
 * @Description: 物业端 订单接口  
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-15 23:55:00
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-03-26 19:47:43
 */
import request from '@/request/request'

// 获取下拉楼盘列表
export function selectHouses(data) {
    return request({
        url: '/Property/Order/getSelectHouses',
        method: 'post',
        data
    })
}

// 获取下拉电梯电梯
export function selectElevator(data) {
    return request({
        url: '/Property/Order/getSelectElevator',
        method: 'post',
        data
    })
}

// 获取下拉故障列表
export function selectFault(data) {
    return request({
        url: '/Property/Order/getSelectFault',
        method: 'post',
        data
    })
}


// 物业发布维修工单
export function addAlarm(data) {
    return request({
        url: '/Property/Order/addAlarm',
        method: 'post',
        data
    })
}

// 物业发布救援工单
export function addRescue(data) {
    return request({
        url: '/Property/Order/addRescue',
        method: 'post',
        data
    })
}

// 物业签字
export function PropertySign(data) {
    return request({
        url: '/Property/PropertySign',
        method: 'post',
        data
    })
}










 